<template>
  <div class="nav-header">
    <div class="container">
      <!-- 左侧 logo -->
      <div class="n-logo">
        <!-- <img src="" alt="" /> -->
        <h2>北京泰名尔文化艺术有限公司  </h2>
      </div>
      <!-- 右侧 导航 -->
      <div class="n-list">
        <a href="/">首页</a>
        <!-- <a href="/#/ac1">独家新闻</a> -->
        <a href="javascript:;" @click="goAc2()">收费资讯</a>
        <!-- <a href="javascript:;" @click="goInfo()">关于我们</a> -->

        <!-- 登录 -->
        <a
          href="javascript:;"
          class="n-login"
          @click="goLogin()"
          v-show="this.LoggedShow"
        >
          <i class="iconfont icon-denglu"></i>
          登录
        </a>
        <!-- 注册 -->
        <a
          href="javascript:;"
          class="n-login"
          @click="goRegister()"
          v-show="this.LoggedShow"
        >
          <i class="iconfont icon-zhuce"></i>
          注册
        </a>
        <!-- 用户名 -->
        <a
          class="n-info"
          v-show="!this.LoggedShow"
          v-text="this.nv_user.Username"
          ><i class="iconfont icon-denglu"></i
        ></a>
        <!-- 退出登录 -->
        <a class="n-info" @click="tolg()" v-show="!this.LoggedShow">
          <i class="iconfont icon-tuichudenglu"></i>
          退出登录
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 按钮显示/隐藏
      LoggedShow: true,
      // 获取到 user 用户信息
      nv_user: JSON.parse(window.localStorage.getItem("user")),
      // 存储状态
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.logged();
  },
  methods: {
    // 登录时隐藏逻辑
    logged() {
      if (this.state == "0") {
        this.LoggedShow = false;
      }
    },

    // 登录跳转
    goLogin() {
      this.$router.push("/login");
    },
    // 注册跳转
    goRegister() {
      this.$router.push("/register");
    },
    // 退出登录
    tolg() {
      window.localStorage.setItem("state", "10");
      this.LoggedShow = true;
      this.$router.push("/");
      location.reload();
      // this.$forceUpdate()
    },

    // 跳转 文职管理岗
    goAc1() {
      this.$router.push("/ac1");
    },
    // 跳转 文职技能岗
    goAc2() {
      this.$router.push("/ac2");
    },
    // 跳转 公务员
    goAc3() {
      this.$router.push("/ac3");
    },

    // 跳转注册协议
    goXy() {
      this.$router.push("/xy");
    },
    // 首页跳转
    goHome() {
      this.$router.push("/");
    },
    goInfo() {
      this.$router.push("/info");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.nav-header {
  width: 100%;
  height: 70px;
  background-color: #343639;
  .container {
    height: 100%;
    @include flex();
    // logo
    .n-logo {
      width: 300px;
      height: 100%;
      h2 {
        font-size: 21px;
        color: #fff;
        line-height: 70px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    // 导航
    .n-list {
      width: 700px;
      height: 100%;
      //   background-color: pink;
      display: flex;
      align-items: center;
      a {
        flex: 1;
        display: inline-block;
        // background-color: red;
        height: 100%;
        // align-items: center;
        line-height: 70px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        cursor: pointer;
        &:hover {
          background-color: #d90000;
        }
      }
    }
  }
}
</style>
