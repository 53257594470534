<template>
  <div class="ac2">
    <nav-header></nav-header>
    <div class="container">
      <!-- banner 层 -->
      <div class="banner">
        <img src="/imgs/b1.jpg" alt="" />
      </div>

      <!-- 区块2 -->
      <a3></a3>
    </div>
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import NavFooter from "./../components/NavFooter";
import NavHeader from "./../components/NavHeader";
import a3 from "./../components/a3";
export default {
  components: {
    a3,
    NavFooter,
    NavHeader,
  },
  mounted() {
    document.title = "北京泰名尔文化艺术有限公司 ";
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.ac2 {
  background-color: #e9eaec;
  // padding-top: 20px;
  box-sizing: border-box;
  .container {
    margin-top: 30px;
    // banner 层
    .banner {
      width: 100%;
      height: 200px;
      background-color: pink;
      // margin: 20px auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>