<template>
  <div class="ac2">
    <!-- 左侧 -->
    <div class="a2-l">
      <!-- 标题 -->
      <div class="l-title">收费资讯</div>
      <div class="a2-box">
        <!-- 循环项 -->
        <div
          class="l-item"
          v-for="(item, index) in this.a3"
          :key="index"
          @click="goDays(item.methods, item.id)"
        >
          <!-- 图片 -->
          <div class="item-img">
            <img :src="item.img" alt="" />
          </div>
          <!-- 信息 -->
          <div class="item-info">
            <!-- 标题 -->
            <div class="item-title">{{ item.title }}</div>
            <!-- 小信息层 -->
            <div class="item-user">
              <!-- 作者 -->
              <span>
                <i class="iconfont icon-wenzhang"></i>
                {{ item.author }}
              </span>
              <!-- 时间 -->
              <span>
                <!-- <i class="iconfont"></i>
                {{ item.time }} -->
              </span>
              <!-- 观看人数 -->
              <span>
                <i class="iconfont icon-denglu"></i>
                {{item.sub}}
              </span>
              <span>
                {{ item.pay }}
              </span>
            </div>
            <!-- 简介 -->
            <div class="item-jj">
              {{ item.jj }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 右侧 -->
    <div class="a2-r">
      <!-- 标题 -->
      <div class="r-title">其他推荐</div>
      <!-- 循环项 -->
      <div
        class="r-item"
        v-for="(item, index) in this.a6"
        :key="index"
        @click="goDays(item.methods, item.id)"
      >
        <!-- 图片 -->
        <div class="item-img">
          <img :src="item.img" alt="" />
        </div>
        <!-- 标题 -->
        <div class="item-title">{{ item.title }}</div>
      </div>
    </div>
    <modal
      title="请选择支付方式"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img
          style="display: flex; justify-content: center; margin: 0 auto;width: 200px;"
          src="/imgs/pay2.png"
        />
        <h2>微信支付：2元/篇</h2>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "./../components/Modal";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      data: [],
      a3: [],
      a6: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init();
    document.title = "北京华谊保险销售有限公司";
  },
  methods: {
    init() {
      this.axios.get("/mock/info.json").then((res) => {
        const data = res.data;
        this.data = data;
        this.a3 = data.slice(0, 5);
        this.a6 = data.slice(4, 10);
      });
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 详情
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      alert("请付费2元后浏览！！！~");
      this.showModal = true;
      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("请登录付费2元后可浏览！！！~");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.ac2 {
  width: 100%;
  // height: 1000px;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  box-sizing: border-box;
  //   左侧
  .a2-l {
    width: 65%;
    height: 100%;
    background: #fff;
    margin-right: 40px;
    // padding: 30px;
    box-sizing: border-box;
    padding-top: 30px;

    // 标题
    .l-title {
      width: 100%;
      height: 50px;
      // border-bottom: 1px solid #ccc;
      font-size: 18px;
      //   font-weight: bold;
      line-height: 50px;
      padding-left: 15px;
      box-sizing: border-box;
      border-left: 3px solid #c60223;
      margin-bottom: 30px;
    }
    .a2-box {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      // 循环项
      .l-item {
        width: 100%;
        height: 200px;
        display: flex;
        align-items: center;
        margin: 10px 0;
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
          background: #eee;
        }
        // 图片
        .item-img {
          width: 200px;
          height: 200px;
          // background-color: #ff6600;
          margin-right: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        // 信息层
        .item-info {
          // 标题
          .item-title {
            font-size: 16px;
          }
          // 小信息层
          .item-user {
            margin: 20px auto;
            span {
              font-size: 14px;
              margin-right: 15px;
            }
          }
        }
        // 简介
        .item-jj {
          width: 400px;
          font-size: 12px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
      }
    }
  }
  //   右侧
  .a2-r {
    width: 35%;
    height: 100%;
    background-color: #fff;
    padding-top: 30px;
    box-sizing: border-box;
    // 标题
    .r-title {
      font-size: 18px;
      width: 100%;
      border-left: 3px solid #c60023;
      height: 30px;
      line-height: 30px;
      padding-left: 10px;
      box-sizing: border-box;
      margin-bottom: 30px;
    }

    // 循环项
    .r-item {
      width: 100%;
      height: 100px;
      // background-color: pink;
      display: flex;
      align-items: center;
      margin: 35px auto;
      cursor: pointer;
      &:hover {
        .item-title {
          color: #c60023;
        }
      }
      // 图片;
      .item-img {
        width: 350px;
        height: 100%;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      //   标题
      .item-title {
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 400px;
        transition: all 0.2s;
      }
    }
  }
}
</style>
