<template>
  <div class="nav-footer">
    <div class="container">
      <div class="f-con">
        <!-- 左侧 -->
        <ul>
          <li>公司名称：北京泰名尔文化艺术有限公司</li>
          <li>公司地址：北京市朝阳区高碑店乡北花园村南3-1号FC01号楼228室</li>
          <li>电话：13508621698</li>
          <li>邮箱：815087703@qq.com</li>
        </ul>
        <!-- 右侧 -->
        <div class="f-jj">
          <img src="/imgs/b2.jpg" alt="" />
        </div>
      </div>
    </div>
    <!-- icp -->
    <div class="f-icp">
      <div class="container">
        <!-- 左侧公司名 -->
        <span>
          <!-- 北京香菇科技有限公司 -->
          <!-- <a href="/imgs/zj.jpg" target="_blink">营业执照</a>  -->
        </span>
        <!-- 右侧 icp -->
        <a href="https://beian.miit.gov.cn/#/Integrated/index"
          >备案号：********</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    email: String,
    phone: String,
    icp: String,
    name: String,
    addr: String,
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.nav-footer {
  width: 100%;
  height: 300px;
  background-color: #e9eaec;
  // margin-top: 30px;
  padding-top: 30px;
  box-sizing: border-box;
  .container {
    .f-con {
      width: 100%;
      height: 250px;
      background: #fff;
      @include flex();
      padding: 30px;
      box-sizing: border-box;
      // 左侧
      ul {
        li {
          font-size: 14px;
          margin: 30px auto;
        }
      }
      // 右侧简介
      .f-jj {
        width: 500px;
        height: 200px;
        // background-color: pink;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  // ipc
  .f-icp {
    width: 100%;
    height: 50px;
    background-color: #343639;
    color: #fff;
    .container {
      height: 100%;
      @include flex();
      span {
        font-size: 14px;
        a {
          font-size: 12px;
          margin-left: 15px;
        }
      }
      a {
        display: inline-block;
        color: #fff;
        font-size: 14px;
      }
    }
  }
}
</style>
