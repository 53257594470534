<template>
  <div class="ac1">
    <!-- 左侧轮播 -->
    <div class="a1-swiper">
      <img src="/imgs/info.jpg" alt="" />
    </div>
    <!-- 中测 头条 -->
    <div class="a1-tt">
      <!-- 标题 -->
      <div class="tt-title">头条推荐</div>
      <!-- 循环项 -->
      <div
        class="tt-item"
        v-for="(item, index) in this.a3"
        :key="index"
        @click="gopay(item.methods, item.id)"
      >
        <!-- 标题 -->
        <div class="item-title">{{ item.title }}</div>
        <!-- 简介 -->
        <div class="item-jj">{{ item.article1 }}</div>
      </div>
    </div>
    <!-- 右侧文章 -->
    <div class="a1-wz">
      <!-- 标题 -->
      <div class="wz-title">热门文章</div>

      <ul>
        <!-- 循环项 -->
        <li
          class="wz-item"
          v-for="(item, index) in this.a6"
          :key="index"
          @click="gopay(item.methods, item.id)"
        >
          <!-- id -->
          <div class="item-id">{{ item.id }}</div>
          <!-- 标题 -->
          <div class="item-title">{{ item.title }}</div>
        </li>
      </ul>
    </div>
     <modal
      title="请选择支付方式"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img
          style="display: flex; justify-content: center; margin: 0 auto;width: 200px;"
          src="/imgs/pay2.png"
        />
        <h2>微信支付：2元/篇</h2>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "./../components/Modal";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      data: [],
      a3: [],
      a6: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init();
    document.title = "北京蕴梦科技有限公司";
  },
  methods: {
    init() {
      this.axios.get("/mock/ac1.json").then((res) => {
        const data = res.data;
        this.data = data;
        this.a3 = data.slice(0, 3);
        this.a6 = data.slice(2, 8);
      });
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 详情
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
     gopay() {
      alert("请付费2元后浏览！！！~");
      this.showModal = true;
      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("请登录付费2元后可浏览！！！~");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.ac1 {
  width: 100%;
  height: 400px;
  margin: 60px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // 轮播
  .a1-swiper {
    width: 400px;
    height: 400px;
    // background-color: #fff;
    img {
      width: 100%;
      height: 100%;
    }
  }
  // 中测,头条
  .a1-tt {
    width: 400px;
    height: 400px;
    background-color: #fff;
    margin: 0 15px;
    padding: 30px 15px 0 15px;
    box-sizing: border-box;
    // 标题
    .tt-title {
      width: 100%;
      height: 30px;
      border-left: 3px solid #c60023;
      font-size: 16px;
      line-height: 30px;
      padding-left: 10px;
      box-sizing: border-box;
      margin-bottom: 30px;
    }
    // 循环项
    .tt-item {
      width: 100%;
      height: 80px;
     
      margin: 20px auto;
      border-bottom: 1px dashed #ccc;
      cursor: pointer;
      &:hover {
        .item-title {
          color: #c60023;
        }
      }

      // 标题
      .item-title {
        font-size: 19px;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        transition: all 0.2s;
      }

      // 简介
      .item-jj {
        font-size: 12px;
        margin-top: 5px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }
  }
  // 右侧文章
  .a1-wz {
    width: 400px;
    height: 400px;
    background-color: #fff;
    padding: 30px 15px 0 15px;
    box-sizing: border-box;
    // 标题
    .wz-title {
      width: 100%;
      height: 30px;
      border-left: 3px solid #c60023;
      font-size: 16px;
      line-height: 30px;
      padding-left: 10px;
      box-sizing: border-box;
      margin-bottom: 30px;
    }

    // 循环项
    .wz-item {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      margin: 10px auto;
      &:hover {
        .item-id {
          background: #c60023;
          color: #fff;
        }
        .item-title {
          color: #c60023;
        }
      }
      // id
      .item-id {
        width: 40px;
        height: 40px;
        background-color: #eee;
        text-align: center;
        line-height: 40px;
        margin-right: 15px;
        font-weight: bold;
        transition: all 0.2s;
      }
      // 标题
      .item-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        width: 300px;
        transition: all 0.2s;
        cursor: pointer;
      }
    }
  }
}
</style>
