<template>
  <div class="info">
    <nav-header></nav-header>
    <!-- banner -->
    <div class="banner">
      <img src="/imgs/info2.jpg" alt="" />
    </div>

    <div class="container">
      <div class="l">
        <img src="/imgs/info3.jpg" alt="" />
      </div>
      <div class="r">
        <h2>北京姆大陆科技有限公司</h2>
        <h4>Beijing Midland Technology Co., Ltd</h4>
        <p>
          北京姆大陆科技有限公司成立于2022年08月17日，注册地位于北京市延庆区八达岭镇文体服务办公楼796号（集群注册），经营范围包括一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；电子产品销售；计算机软硬件及辅助设备零售；信息系统集成服务；广告发布；广告设计、代理；广告制作；企业管理；信息咨询服务（不含许可类信息咨询服务）；组织文化艺术交流活动；电影摄制服务；货物进出口；技术进出口；进出口代理；食品销售（仅销售预包装食品）；软件开发；互联网销售（除销售需要许可的商品）。（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）许可项目：网络文化经营；互联网信息服务；第二类增值电信业务；信息网络传播视听节目；基础电信业务；第一类增值电信业务；出版物零售；广播电视节目制作经营；电影发行。（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准）（不得从事国家和本市产业政策禁止和限制类项目的经营活动。）
        </p>
      </div>
    </div>
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import NavFooter from "./../components/NavFooter";
import NavHeader from "./../components/NavHeader";
export default {
  mounted() {
    document.title = "姆大陆";
  },
  components: {
    NavHeader,
    NavFooter,
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.info {
  width: 100%;
  background-color: #e9eaec;
  position: relative;
  //   banner
  .banner {
    width: 100%;
    height: 450px;
    background-color: pink;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .container {
    height: 500px;
    // margin: 30px auto;
    padding: 30px 0;
    // box-sizing: border-box;
    box-sizing: border-box;
    @include flex();
    .l {
      width: 50%;
      height: 100%;
      //   background: #c60023;
      margin-right: 30px;
      img {
        widows: 100%;
        height: 100%;
      }
    }
    .r {
      height: 100%;
      width: 50%;
      background-color: #fff;
      padding: 30px;
      box-sizing: border-box;
      h2 {
        font-size: 18px;
        // font-weight: 400;
        color: #333;
      }
      h4 {
        font-size: 14px;
        color: #333;
        font-weight: 400;
        margin: 10px 0 40px;
      }

      p {
        font-size: 12px;
        color: #888;
        line-height: 30px;
        text-indent: 30px;
      }
    }
  }
}
</style>
