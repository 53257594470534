<template>
  <div class="index">
    <!-- 导航栏 -->
    <nav-header></nav-header>

    <div class="container">
      <!-- 主容器，分左右，右边长列表 -->
      <div class="i-con">
        <!-- 右侧长列表 -->
        <div class="i-r">
          <!-- 推荐 -->
          <div class="z1-con">
            <!-- 推荐方块 -->
            <span>本站推荐</span>
            <!-- 标题 -->
            <div class="z1-title">边缘计算应对互联网的碎片化</div>
            <!-- 简介 -->
            <div class="z1-brief">
              工业互联网在跑道上飞速前进。今天的互联网不仅仅只做互联这件事情，互联网已经变成了分布式的数据处理器和分布式数据采集器，底层通讯、上层存储、上层应用和计算融为一体，这是非常重要的转变。
            </div>
          </div>

          <!-- 资讯区块2 -->
          <div class="z2-con">
            <!-- 标题 -->
            <div class="z2-title"><span>最新文章</span><span></span></div>

            <!-- 列表 -->
            <div class="ul">
              <!-- 循环项 -->
              <li
                class="z2-item"
                v-for="(item, index) in ac3"
                :key="index"
                @click="gopay(item.methods, item.id)"
              >
                <img :src="item.img" alt="" />
                <div class="z2-info">
                  <div class="z2-t">{{ item.title }}</div>
                  <div class="z2-time">{{ item.time }}</div>
                </div>
              </li>
            </div>
          </div>
        </div>

        <!-- 左侧长内容 -->
        <div class="i-l">
          <!-- 头部 -->
          <div class="s-header">
            <h2>{{ this.title }}</h2>
            <span>{{ this.author }}</span>
            <!-- <span>发布: {{ this.time }} </span> -->
            <!-- <span>{{ this.sub }}阅读</span> -->
          </div>

          <!-- 内容 -->
          <div class="s-con">
            <!-- 图片 -->
            <img :src="this.img" alt="" />
            <!-- 文字 -->
            <div class="s-text">
              <p>{{ this.article1 }}</p>
              <p>{{ this.article2 }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      title="请选择支付方式"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img
          style="display: flex; justify-content: center; margin: 0 auto"
          src="/imgs/pay2.png"
        />
        <h2>微信支付：2元/篇</h2>
      </template>
    </modal>

    <nav-footer></nav-footer>
  </div>
</template>

<script>
import NavFooter from "./../components/NavFooter";
import NavHeader from "./../components/NavHeader";
import Modal from "./../components/Modal";
export default {
  data() {
    return {
      href: "index.html",
      // 获取 id
      myId: this.$route.query.type,
      // 获取 标题
      title: "",
      // 简介
      concise: "",
      // 作者
      author: "",
      // 图片路径
      img: "",

      // 文章1
      article1: "",
      // 文章2
      article2: "",
      time: "",
      ac3: [],
      sub: "",
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  components: {
    NavHeader,
    NavFooter,
    Modal,
  },
  mounted() {
    this.init();
    document.title = "姆大陆";
  },
  methods: {
    init() {
      this.axios.get("/mock/ac1.json").then((res) => {
        var vl = res.data;
        var date = vl.find((vl) => vl.id == this.myId);
        console.log(date);
        // console.log(date)
        // 标题
        this.title = date.title;
        // 作者
        this.author = date.author;
        // 歌词
        this.concise = date.concise;
        // 图片路径
        this.img = date.img;
        // 文章 1
        this.article1 = date.article1;
        // 文章 2
        this.article2 = date.article2;
        this.time = date.time;
        this.sub = date.sub;
        this.ac3 = vl.slice(0, 7);
      });
    },
    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
      location.reload();
    },
    // 收费逻辑
    gopay() {
      // console.log('1')

      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
        console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("请登录付费2元后可浏览！！！~");
        this.$router.push({ path: "/register", query: { k: 1 } });
        return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.index {
  background-color: #e9eaec;
  // padding: 30px 0;
  box-sizing: border-box;

  .container {
    margin-top: 30px;
    // 主容器1
    .i-con {
      display: flex;
      justify-content: space-between;
      width: 100%;
      // height: 3000px;
      // background-color: pink;
      // margin: 160px auto 60px;
      // 左侧
      .i-l {
        padding: 20px;
        width: 70%;
        height: 100%;
        background-color: #fff;
        box-sizing: border-box;

        // 头部
        .s-header {
          width: 100%;
          height: 100px;
          background-color: #fff;
          text-align: center;
          h2 {
            margin-bottom: 30px;
          }
          span {
            font-size: 14px;
            margin: 0 15px;
          }
        }
        // 文章容器
        .s-con {
          // 图片
          img {
            width: 100%;
            height: 400px;
            margin: 30px auto;
            background-color: pink;
          }
          // 文章
          .s-text {
            width: 100%;
            text-align: left;
            padding: 20px;
            box-sizing: border-box;
            p {
              margin: 20px auto;
              font-size: 14px;
              line-height: 70px;
            }
          }
        }
      }

      // 右侧
      .i-r {
        width: 30%;
        height: 100%;
        // background-color: #c60023;
        box-sizing: border-box;
        margin-right: 30px;

        // 推荐区块
        .z1-con {
          width: 100%;
          height: 190px;
          background-color: #fff;
          padding-left: 10px;
          box-sizing: border-box;
          span {
            border: none;
            border-radius: 0;
            color: #fff;
            display: inline-block;
            font-size: 14px;
            padding: 5px 15px;
            width: auto;
            margin: -1px 0 0 15px;
            z-index: 2;
            background-color: #c60023;
          }
          // 标题
          .z1-title {
            font-size: 24px;
            font-weight: 400;
            margin-bottom: 10px;
            color: #ff4c4c;
            margin-top: 20px;
            font-weight: bold;
          }
          // 简介
          .z1-brief {
            font-size: 14px;
            line-height: 1.7;
            color: #999;
          }
        }

        // 最新文章区块
        .z2-con {
          background-color: #fff;
          border: 1px solid #eaeaea;
          // margin-bottom: 12px;
          padding: 15px;
          line-height: 1.4;
          margin: 20px auto;
          // 标题
          .z2-title {
            background-color: #fbfbfb;
            border-bottom: 1px solid #eaeaea;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            color: #333;
            font-size: 14px;
            position: relative;
            padding: 9px 15px;
            position: relative;
            top: -15px;
            left: -15px;
            width: 100%;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
          }

          // 循环项
          .z2-item {
            display: flex;
            margin: 15px auto;
            align-items: center;
            // 图片
            img {
              width: 80px;
              height: 80px;
              background-color: #c60023;
              margin-right: 15px;
            }
            // info
            .z2-info {
              width: 70%;
              .z2-t {
                font-size: 14px;
                color: #333;
                font-weight: 400;
              }
              .z2-time {
                font-size: 13px;
                margin-top: 4px;
                color: #999;
              }
            }
          }
        }
      }
    }
  }
}
</style>
