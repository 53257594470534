<template>
  <div class="index">
    <!-- 定宽布局 -->
    <div class="container">
      <!-- banner -->
      <div class="banner">
        <img src="/imgs/b1.jpg" alt="" />
      </div>

      <!-- 区块 -->
      <a1></a1>

      <!-- 区块2 -->
      <a2></a2>
    </div>
  </div>
</template>

<script>
import a1 from "./../components/a1";
import a2 from "./../components/a2";
export default {
  name: "index",
  components: {
    a1,
    a2,
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.index {
  background-color: #e9eaec;
  padding-bottom: 30px;
  box-sizing: border-box;
  padding-top: 20px;
  box-sizing: border-box;

  .container {
    // banner 层
    .banner {
      width: 100%;
      height: 200px;
      background-color: pink;
      // margin: 20px auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
